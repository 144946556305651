export default {
  BRANCO_PADRAO: "#FFFFFF",
  CINZA_CLARO: "#F9F9F9",
  CINZA_PADRAO: "#8B8B8B",
  CINZA_ESCURO: "#40463F",
  CINZA_CONTATO_CARD: "#585858",
  VERDE_CLARO: "#D1FED1",
  VERDE: "#06AF07",
  VERDE_DOIS: "#45A148",
  VERDE_ESCURO: "#185729",
  VERDE_CONTATO_CARD: "#005000",
  VERDE_OPCOES: "#AACF5D",
  ROXO_PASTEL: "#ECE5FF",
  ROXO_ESCURO: "#665697",
  ROXO_ESCURO_DOIS: "#3F1C8E",
  LARANJA_PASTEL: "#fff0de",
  LARANJA_ESCURO: "#B17936",
  PRETO_FOSCO: "#40463F",
  MARROM: "#74613C",
}
