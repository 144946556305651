import styled from "styled-components"
import { createGlobalStyle } from "styled-components"
import reset from "styled-reset"

import colors from "./colors"

import "../fonts/stylesheet.css"

export const Container = styled.div`
  max-width: 1200px;
  padding: 0px;
  margin: 0 auto;
`

export const GlobalStyle = createGlobalStyle`
  ${reset}

  html {
   box-sizing: border-box;
   height: 100%;
   font-family: 'Founders Grotesk', sans-serif;
   color: black;

   text-rendering: auto;
   -ms-text-size-adjust: 100%;
   -webkit-text-size-adjust: 100%;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
  }


  form {
    input:focus {
      outline: none;
      border-color: ${colors.VERDE};
    } 
   
    input {
      border:none;
      background-image:none;
      background-color:transparent;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      font-family: 'Founders Grotesk', sans-serif !important; 
    }

    textarea:focus {
      outline: none;
      border-color: ${colors.VERDE};
    } 
  }

  img {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  body {
    height: 100%;
    font-family: 'Founders Grotesk', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: auto;
    font-size: 18px;
    overflow-x: hidden;
  }

  button {
    font-size: 16px;
    background: none;
    font-family: 'Founders Grotesk', sans-serif;
    outline: none;

    &:hover {
      cursor: pointer;
    }
  }

  table {
    font-family: 'Founders Grotesk', sans-serif !important; 
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  .g-image {
    max-width: 300px;
  }

  h1,h2,h3,h4,h5,h6,p,a, img, button, br {
    ::selection {
     color: ${colors.BRANCO_PADRAO};
     background-color:  ${colors.VERDE_ESCURO};
    }
    font-family: 'Founders Grotesk', sans-serif !important; 
  }
`
