// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-js": () => import("./../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-empresa-js": () => import("./../src/pages/empresa.js" /* webpackChunkName: "component---src-pages-empresa-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-produtos-balas-macias-de-gengibre-forte-js": () => import("./../src/pages/produtos/balas-macias-de-gengibre-forte.js" /* webpackChunkName: "component---src-pages-produtos-balas-macias-de-gengibre-forte-js" */),
  "component---src-pages-produtos-balas-macias-de-gengibre-frutas-citricas-js": () => import("./../src/pages/produtos/balas-macias-de-gengibre-frutas-citricas.js" /* webpackChunkName: "component---src-pages-produtos-balas-macias-de-gengibre-frutas-citricas-js" */),
  "component---src-pages-produtos-balas-macias-de-gengibre-js": () => import("./../src/pages/produtos/balas-macias-de-gengibre.js" /* webpackChunkName: "component---src-pages-produtos-balas-macias-de-gengibre-js" */),
  "component---src-pages-produtos-balas-macias-de-gengibre-limao-js": () => import("./../src/pages/produtos/balas-macias-de-gengibre-limao.js" /* webpackChunkName: "component---src-pages-produtos-balas-macias-de-gengibre-limao-js" */),
  "component---src-pages-produtos-balas-macias-de-gengibre-maca-verde-js": () => import("./../src/pages/produtos/balas-macias-de-gengibre-maca-verde.js" /* webpackChunkName: "component---src-pages-produtos-balas-macias-de-gengibre-maca-verde-js" */),
  "component---src-pages-produtos-balas-macias-de-gengibre-menta-js": () => import("./../src/pages/produtos/balas-macias-de-gengibre-menta.js" /* webpackChunkName: "component---src-pages-produtos-balas-macias-de-gengibre-menta-js" */),
  "component---src-pages-produtos-balas-macias-de-gengibre-suave-js": () => import("./../src/pages/produtos/balas-macias-de-gengibre-suave.js" /* webpackChunkName: "component---src-pages-produtos-balas-macias-de-gengibre-suave-js" */),
  "component---src-pages-produtos-barras-de-goma-de-gengibre-js": () => import("./../src/pages/produtos/barras-de-goma-de-gengibre.js" /* webpackChunkName: "component---src-pages-produtos-barras-de-goma-de-gengibre-js" */),
  "component---src-pages-produtos-bebida-gaseificada-a-base-de-gengibre-js": () => import("./../src/pages/produtos/bebida-gaseificada-a-base-de-gengibre.js" /* webpackChunkName: "component---src-pages-produtos-bebida-gaseificada-a-base-de-gengibre-js" */),
  "component---src-pages-produtos-bebida-gaseificada-limao-js": () => import("./../src/pages/produtos/bebida-gaseificada-limao.js" /* webpackChunkName: "component---src-pages-produtos-bebida-gaseificada-limao-js" */),
  "component---src-pages-produtos-bebida-gaseificada-limao-zero-js": () => import("./../src/pages/produtos/bebida-gaseificada-limao-zero.js" /* webpackChunkName: "component---src-pages-produtos-bebida-gaseificada-limao-zero-js" */),
  "component---src-pages-produtos-cristais-de-gengibre-disco-acerola-js": () => import("./../src/pages/produtos/cristais-de-gengibre-disco-acerola.js" /* webpackChunkName: "component---src-pages-produtos-cristais-de-gengibre-disco-acerola-js" */),
  "component---src-pages-produtos-cristais-de-gengibre-disco-anis-js": () => import("./../src/pages/produtos/cristais-de-gengibre-disco-anis.js" /* webpackChunkName: "component---src-pages-produtos-cristais-de-gengibre-disco-anis-js" */),
  "component---src-pages-produtos-cristais-de-gengibre-disco-canela-js": () => import("./../src/pages/produtos/cristais-de-gengibre-disco-canela.js" /* webpackChunkName: "component---src-pages-produtos-cristais-de-gengibre-disco-canela-js" */),
  "component---src-pages-produtos-cristais-de-gengibre-disco-limao-sal-js": () => import("./../src/pages/produtos/cristais-de-gengibre-disco-limao-sal.js" /* webpackChunkName: "component---src-pages-produtos-cristais-de-gengibre-disco-limao-sal-js" */),
  "component---src-pages-produtos-cristais-de-gengibre-disco-maracuja-js": () => import("./../src/pages/produtos/cristais-de-gengibre-disco-maracuja.js" /* webpackChunkName: "component---src-pages-produtos-cristais-de-gengibre-disco-maracuja-js" */),
  "component---src-pages-produtos-cristais-de-gengibre-disco-menta-js": () => import("./../src/pages/produtos/cristais-de-gengibre-disco-menta.js" /* webpackChunkName: "component---src-pages-produtos-cristais-de-gengibre-disco-menta-js" */),
  "component---src-pages-produtos-cristais-de-gengibre-em-pote-js": () => import("./../src/pages/produtos/cristais-de-gengibre-em-pote.js" /* webpackChunkName: "component---src-pages-produtos-cristais-de-gengibre-em-pote-js" */),
  "component---src-pages-produtos-cristais-de-gengibre-em-tubo-js": () => import("./../src/pages/produtos/cristais-de-gengibre-em-tubo.js" /* webpackChunkName: "component---src-pages-produtos-cristais-de-gengibre-em-tubo-js" */),
  "component---src-pages-produtos-cristal-tubo-acerola-js": () => import("./../src/pages/produtos/cristal-tubo-acerola.js" /* webpackChunkName: "component---src-pages-produtos-cristal-tubo-acerola-js" */),
  "component---src-pages-produtos-cristal-tubo-anis-js": () => import("./../src/pages/produtos/cristal-tubo-anis.js" /* webpackChunkName: "component---src-pages-produtos-cristal-tubo-anis-js" */),
  "component---src-pages-produtos-cristal-tubo-canela-js": () => import("./../src/pages/produtos/cristal-tubo-canela.js" /* webpackChunkName: "component---src-pages-produtos-cristal-tubo-canela-js" */),
  "component---src-pages-produtos-cristal-tubo-limao-sal-js": () => import("./../src/pages/produtos/cristal-tubo-limao-sal.js" /* webpackChunkName: "component---src-pages-produtos-cristal-tubo-limao-sal-js" */),
  "component---src-pages-produtos-cristal-tubo-maracuja-js": () => import("./../src/pages/produtos/cristal-tubo-maracuja.js" /* webpackChunkName: "component---src-pages-produtos-cristal-tubo-maracuja-js" */),
  "component---src-pages-produtos-cristal-tubo-menta-js": () => import("./../src/pages/produtos/cristal-tubo-menta.js" /* webpackChunkName: "component---src-pages-produtos-cristal-tubo-menta-js" */),
  "component---src-pages-produtos-extrato-de-gengibre-concentrado-js": () => import("./../src/pages/produtos/extrato-de-gengibre-concentrado.js" /* webpackChunkName: "component---src-pages-produtos-extrato-de-gengibre-concentrado-js" */),
  "component---src-pages-produtos-extrato-de-gengibre-zero-js": () => import("./../src/pages/produtos/extrato-de-gengibre-zero.js" /* webpackChunkName: "component---src-pages-produtos-extrato-de-gengibre-zero-js" */),
  "component---src-pages-produtos-gomas-de-gengibre-em-pote-js": () => import("./../src/pages/produtos/gomas-de-gengibre-em-pote.js" /* webpackChunkName: "component---src-pages-produtos-gomas-de-gengibre-em-pote-js" */),
  "component---src-pages-produtos-gomas-de-gengibre-js": () => import("./../src/pages/produtos/gomas-de-gengibre.js" /* webpackChunkName: "component---src-pages-produtos-gomas-de-gengibre-js" */),
  "component---src-pages-produtos-gomas-pote-laranja-js": () => import("./../src/pages/produtos/gomas-pote-laranja.js" /* webpackChunkName: "component---src-pages-produtos-gomas-pote-laranja-js" */),
  "component---src-pages-produtos-gomas-pote-laranja-zero-js": () => import("./../src/pages/produtos/gomas-pote-laranja-zero.js" /* webpackChunkName: "component---src-pages-produtos-gomas-pote-laranja-zero-js" */),
  "component---src-pages-produtos-gomas-pote-menta-js": () => import("./../src/pages/produtos/gomas-pote-menta.js" /* webpackChunkName: "component---src-pages-produtos-gomas-pote-menta-js" */),
  "component---src-pages-produtos-gomas-pote-menta-zero-js": () => import("./../src/pages/produtos/gomas-pote-menta-zero.js" /* webpackChunkName: "component---src-pages-produtos-gomas-pote-menta-zero-js" */),
  "component---src-pages-produtos-gomas-pote-original-js": () => import("./../src/pages/produtos/gomas-pote-original.js" /* webpackChunkName: "component---src-pages-produtos-gomas-pote-original-js" */),
  "component---src-pages-produtos-gomas-pote-original-zero-js": () => import("./../src/pages/produtos/gomas-pote-original-zero.js" /* webpackChunkName: "component---src-pages-produtos-gomas-pote-original-zero-js" */),
  "component---src-pages-produtos-gomas-sache-laranja-js": () => import("./../src/pages/produtos/gomas-sache-laranja.js" /* webpackChunkName: "component---src-pages-produtos-gomas-sache-laranja-js" */),
  "component---src-pages-produtos-gomas-sache-laranja-zero-js": () => import("./../src/pages/produtos/gomas-sache-laranja-zero.js" /* webpackChunkName: "component---src-pages-produtos-gomas-sache-laranja-zero-js" */),
  "component---src-pages-produtos-gomas-sache-menta-js": () => import("./../src/pages/produtos/gomas-sache-menta.js" /* webpackChunkName: "component---src-pages-produtos-gomas-sache-menta-js" */),
  "component---src-pages-produtos-gomas-sache-menta-zero-js": () => import("./../src/pages/produtos/gomas-sache-menta-zero.js" /* webpackChunkName: "component---src-pages-produtos-gomas-sache-menta-zero-js" */),
  "component---src-pages-produtos-gomas-sache-original-js": () => import("./../src/pages/produtos/gomas-sache-original.js" /* webpackChunkName: "component---src-pages-produtos-gomas-sache-original-js" */),
  "component---src-pages-produtos-gomas-sache-original-zero-js": () => import("./../src/pages/produtos/gomas-sache-original-zero.js" /* webpackChunkName: "component---src-pages-produtos-gomas-sache-original-zero-js" */),
  "component---src-pages-produtos-js": () => import("./../src/pages/produtos.js" /* webpackChunkName: "component---src-pages-produtos-js" */),
  "component---src-pages-produtos-puro-extrato-de-gengibre-js": () => import("./../src/pages/produtos/puro-extrato-de-gengibre.js" /* webpackChunkName: "component---src-pages-produtos-puro-extrato-de-gengibre-js" */),
  "component---src-pages-produtos-tempero-de-gengibre-js": () => import("./../src/pages/produtos/tempero-de-gengibre.js" /* webpackChunkName: "component---src-pages-produtos-tempero-de-gengibre-js" */),
  "component---src-pages-saiba-mais-js": () => import("./../src/pages/saiba-mais.js" /* webpackChunkName: "component---src-pages-saiba-mais-js" */),
  "component---src-pages-seja-parceiro-js": () => import("./../src/pages/seja-parceiro.js" /* webpackChunkName: "component---src-pages-seja-parceiro-js" */)
}

